import React, { useEffect, useState } from 'react';
import {
  calculateBMI,
  getAddress,
  getFullName,
  getHeightWithUnit,
  getNextAppointment,
  getWeightWithUnit,
} from '../../../../utils/Helper';
import PatientProfileImage from './PatientProfileImage';
import { getNextBookingByPatientId } from '../../../../services/api';
import { TriangleAlert } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../components/UI';
import { notify } from '../../../../components/common';

const PatientInfo: React.FC<PatientProfileInfoProps> = ({
  patient,
  onChangeProfilePicture,
}) => {
  const [nextAppt, setNextAppt] = useState<Booking | null>(null);

  useEffect(() => {
    const fetchNextAppointment = async () => {
      const [res] = await getNextBookingByPatientId(patient.publicId);
      if (res) {
        setNextAppt(res);
      }
    };

    fetchNextAppointment();
  }, [patient.publicId]);

  const showInfo = (label: string, info: string | number | undefined) => (
    <>
      <p className="font-medium text-[14px] text-neutral-500">{label}</p>
      <p
        className="font-medium text-[14px] text-neutral-900"
        style={{ overflowWrap: 'break-word' }}
      >
        {info || '-'}
      </p>
    </>
  );
  return (
    <div className="grid grid-cols-5 rounded-[8px] border border-neutral-100">
      <div className="col-span-2 border-r border-neutral-100">
        <div className="gird grid-rows-2">
          <div className="px-3 py-4 flex gap-4 items-center justify-start border-b border-neutral-100">
            <PatientProfileImage
              type="patient"
              publicId={patient.publicId}
              patient={patient}
              onChangeProfilePicture={onChangeProfilePicture}
            />
            <div className="space-y-2">
              <p className="font-semibold text-[16px] text-neutral-900">
                {getFullName(patient)}
              </p>
              <div className="flex items-center space-x-2">
                <p className="px-1.5 py-0.75 rounded-[20px] border border-primary-500 bg-primary-50 font-medium text-primary-500 inline-block">
                  {patient.publicId}
                </p>
                {/* {patient.partnerPublicId && (
                  <FlagTooltip
                    content={`Patient has a partner request access for ${patient.partnerPublicId} patient id`}
                  >
                    <Info height={18} className="text-info-600" />
                  </FlagTooltip>
                )} */}
                {patient.allergies && (
                  <FlagTooltip content={`Allergies: ${patient.allergies}`}>
                    <TriangleAlert
                      height={18}
                      className="text-destructive-600"
                      onClick={() => {
                        notify.warning({
                          title: 'Patient has allergies',
                          message: `Patient is allergic to ${patient.allergies}`,
                        });
                      }}
                    />
                  </FlagTooltip>
                )}
                {patient.alerts && (
                  <FlagTooltip content={`Alerts: ${patient.alerts}`}>
                    <TriangleAlert height={18} className="text-warning-500" onClick={() => {
                      notify.warning({
                        title: 'Patient has alerts',
                        message: patient.alerts,
                      });
                    }} />
                  </FlagTooltip>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3">
            <div className="border-r border-b border-neutral-100 px-3 py-4">
              {showInfo('DOB', patient.dateOfBirth)} ({patient.age})
            </div>
            <div className="border-r border-b border-neutral-100 px-3 py-4">
              {showInfo('Gender at birth', patient.gender)}
            </div>
            <div className="border-b border-neutral-100 px-3 py-4">
              {showInfo('Consulted by', getFullName(patient.ownerStaff))}
            </div>
            <div className="border-r border-neutral-100 px-3 py-4">
              {showInfo('BMI', calculateBMI(patient))}
            </div>
            <div className="border-r border-neutral-100 px-3 py-4">
              {showInfo('Height', getHeightWithUnit(patient))}
            </div>
            <div className="px-3 py-4">
              {showInfo('Weight', getWeightWithUnit(patient))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3">
        <h1 className="py-4 px-3 font-medium text-[14px]">
          General Information
        </h1>
        <div className="grid grid-cols-3">
          <div className="px-3 py-6">
            {showInfo('Next Appointment', getNextAppointment(nextAppt))}
          </div>
          <div className="px-3 py-6">
            {showInfo('Contact', patient.phoneNo)}
          </div>
          <div className="px-3 py-6">
            {showInfo('Allergies', patient.allergies)}
          </div>
          <div className="px-3 py-6">
            {showInfo('Address', getAddress(patient.address))}
          </div>
          <div className="px-3 py-6">{showInfo('Email', patient.email)}</div>
          <div className="px-3 py-6">{showInfo('Alerts', patient.alerts)}</div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;

const FlagTooltip = ({ children, content }: any) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <div>{children}</div>
      </TooltipTrigger>
      <TooltipContent className="z-[99999]" side="bottom">
        <div className=" flex flex-col gap-3 font-medium">{content}</div>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);
