import React, { useEffect, useState } from 'react';
import {
  calculateBMI,
  getAddress,
  getFullName,
  getHeightWithUnit,
  getNextAppointment,
  getWeightWithUnit,
} from '../../../../utils/Helper';
import PatientProfileImage from './PatientProfileImage';
import { getNextBookingByPatientId } from '../../../../services/api';
import { TriangleAlert } from 'lucide-react';
import { ArrowUpIcon, DownArrowIcon } from '../../../../assets/icons';
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../components/UI';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/NavigationConstants';

const PatientProfileInfo: React.FC<PatientProfileWithInfoProps> = ({
  patient,
  setExpanded,
  isExpanded,
  ...props
}) => {
  const [nextAppt, setNextAppt] = useState<Booking | null>(null);
  //const [expandGenInfo, setGenInfoOpen] = useState<boolean>(isExpanded || false);

  useEffect(() => {
    const fetchNextAppointment = async () => {
      const [res] = await getNextBookingByPatientId(patient.publicId);
      if (res) {
        setNextAppt(res);
      }
    };

    fetchNextAppointment();
  }, [patient.publicId]);

  const showInfo = (label: string, info: string | number | undefined) => (
    <>
      <p className="font-medium text-[14px] text-neutral-500">{label}</p>
      <p
        className="font-medium text-[14px] text-neutral-900"
        style={{ overflowWrap: 'break-word' }}
      >
        {info || '-'}
      </p>
    </>
  );

  return (
    <div className="border border-neutral-100 rounded w-full">
      <div className="border-b border-neutral-100">
        <div className="flex">
          <div className="px-3 py-4 flex flex-col items-center justify-start border-r border-neutral-100 space-y-2">
            {props.type === 'patient' ? (
              <>
                <PatientProfileImage
                  publicId={patient.publicId}
                  patient={patient}
                  onChangeProfilePicture={props.onChangeProfilePicture}
                  type="patient"
                />

                <p className="font-semibold text-[16px] text-neutral-900 whitespace-nowrap">
                  {getFullName(patient)}
                </p>

                <p className="px-1.5 py-0.75 rounded-[20px] border border-primary-500 bg-primary-50 font-medium text-primary-500 inline-block">
                  {patient.publicId}
                </p>
              </>
            ) : (
              <>
                <Link
                  to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${patient.publicId}`}
                >
                  <PatientProfileImage
                    publicId={patient.publicId}
                    patient={patient}
                    type="partner"
                  />
                </Link>

                <Link
                  to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${patient.publicId}`}
                >
                  <p className="font-semibold text-[16px] text-neutral-900 whitespace-nowrap">
                    {getFullName(patient)}
                  </p>
                </Link>

                <Link
                  to={`${ROUTES.ROUTE_TO_PATIENT_PROFILE}${patient.publicId}`}
                >
                  <p className="px-1.5 py-0.75 rounded-[20px] border border-primary-500 bg-primary-50 font-medium text-primary-500 inline-block">
                    {patient.publicId}
                  </p>
                </Link>
              </>
            )}
            <div className="flex flex-row">
              {patient.allergies && (
                <FlagTooltip content={`Allergies: ${patient.allergies}`}>
                  <TriangleAlert height={18} className="text-destructive-600" />
                </FlagTooltip>
              )}
              {patient.alerts && (
                <FlagTooltip content={`Alerts: ${patient.alerts}`}>
                  <TriangleAlert height={18} className="text-warning-500" />
                </FlagTooltip>
              )}
            </div>
          </div>
          <div className="flex-1 grid grid-cols-3">
            <div className="border-r border-b border-neutral-100 px-3 py-4 flex flex-col justify-center items-start">
              {showInfo('DOB', patient.dateOfBirth || '')} {patient.age && `(${patient.age})`}
            </div>
            <div className="border-r border-b border-neutral-100 px-3 py-4 flex flex-col justify-center items-start">
              {showInfo('Gender at birth', patient.gender || '')}
            </div>
            <div className="border-b border-neutral-100 px-3 py-4 flex flex-col justify-center items-start">
              {showInfo('Consulted by', getFullName(patient.ownerStaff))}
            </div>
            <div className="border-r border-neutral-100 px-3 py-4 flex flex-col justify-center items-start">
              {showInfo('BMI', calculateBMI(patient))}
            </div>
            <div className="border-r border-neutral-100 px-3 py-4 flex flex-col justify-center items-start">
              {showInfo('Height', getHeightWithUnit(patient))}
            </div>
            <div className="px-3 py-4 flex flex-col justify-center items-start">
              {showInfo('Weight', getWeightWithUnit(patient))}
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className={'flex items-center justify-between '}>
          <h1 className="py-4 px-3 font-medium text-[14px]">
            General Information
          </h1>
          <Button
            onClick={() => setExpanded ? setExpanded(!isExpanded) : null}
            trailingIcon={isExpanded ? <ArrowUpIcon /> : <DownArrowIcon />}
            variant={'link'}
            size={'small'}
          >
            {isExpanded ? 'Collapse' : 'Expand'}
          </Button>
        </div>
        {isExpanded && (
          <div className="grid grid-cols-2">
            <div className="px-3 py-6">
              {showInfo('Next Appointment', getNextAppointment(nextAppt))}
            </div>
            <div className="px-3 py-6">
              {showInfo('Address', getAddress(patient.address))}
            </div>
            <div className="px-3 py-6">
              {showInfo('Contact', patient.phoneNo)}
            </div>
            <div className="px-3 py-6">
              {showInfo('Allergies', patient.allergies)}
            </div>
            <div className="px-3 py-6">{showInfo('Email', patient.email)}</div>
            <div className="px-3 py-6">
              {showInfo('Alerts', patient.alerts)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PatientProfileInfo;
const FlagTooltip = ({ children, content }: any) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <div>{children}</div>
      </TooltipTrigger>
      <TooltipContent className="z-[99999]" side="bottom">
        <div className=" flex flex-col gap-3 font-medium">{content}</div>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);
