'use client';
import * as React from 'react';
// import { RightArrow } from '../../assets/icons';
import { DayPicker } from 'react-day-picker';
import { cn } from '../../utils';
import { isAfter } from 'date-fns';

const Calendar = ({
  className,
  classNames,
  showOutsideDays = true,
  disableAfterDate,
  ...props
}: React.ComponentProps<typeof DayPicker> & { disableAfterDate?: Date }) => {
  const isAfterDisableDate = (day: Date) =>
    disableAfterDate ? isAfter(day, disableAfterDate) : false;
  return (
    <>
      {' '}
      <style>
        {`
        .rdp-caption_dropdowns{
          display:flex;
          flex-direction:row;
          gap:1rem;
          align-items:center;
        }
          .rdp-dropdown_month > span {
            display: none;
          }
          .rdp-dropdown_year > span {
            display: none;
          }
          .rdp-dropdown_year select {
            padding-right: 3rem; /* Adjust space for dropdown arrow */
          }
          .rdp-dropdown_month select {
            padding-right: 0.25rem; /* Adjust space for dropdown arrow */
          }
        `}
      </style>
      <DayPicker
        captionLayout="dropdown-buttons"
        fromYear={1970}
        toYear={2030}
        showOutsideDays={showOutsideDays}
        className={cn('p-3', className)}
        modifiers={{
          disabled: isAfterDisableDate,
        }}
        classNames={{
          months:
            'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
          month: 'space-y-4',
          caption:
            'flex justify-between pt-1 relative items-center border-b border-neutral-100 pb-3',
          caption_label: 'hidden',
          vhidden: 'font-medium',
          dropdown: 'text-neutral-900 font-medium',
          // dropdown_year: 'flex justify-between',
          nav: 'space-x-1 flex items-center text-sm',
          table: 'w-full border-collapse space-y-3',
          head_row: 'flex',
          head_cell:
            'text-muted-foreground rounded-md w-9 font-medium text-[0.8rem]',
          row: 'flex w-full mt-2',
          cell: ' h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          day: cn(
            'h-9 w-9 p-0 font-medium aria-selected:opacity-100 rounded-full'
          ),
          day_range_end: 'day-range-end',
          day_selected:
            'bg-shades-100 text-shades-0 hover:bg-shades-100 hover:text-shades-0 focus:bg-shades-100 focus:text-shades-0',
          day_today: 'text-destructive-500',
          day_outside:
            'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
          day_disabled: 'text-muted-foreground opacity-50',
          day_range_middle:
            'aria-selected:bg-accent aria-selected:text-accent-foreground',
          day_hidden: 'invisible',
          ...classNames,
        }}
        components={{
          IconLeft: () => (
            <div className="-rotate-180">
              {/* <RightArrow height={'20'} width={'20'} /> */}
            </div>
          ),
          IconRight: () => <div></div>,
        }}
        {...props}
      />
    </>
  );
};

Calendar.displayName = 'Calendar';
export { Calendar };
